<template>
  <div class="rule">
    <div class="vote-rule">
      <h3 class="title">答题规则</h3>
      <Form
        :model="formValidate"
        :label-colon="true"
        :label-width="140"
        :rules="ruleValidate"
        ref="formValidate"
      >
        <FormItem label="题库分类" prop="questionBankId">
          <Select v-model="formValidate.questionBankId">
            <Option
              :value="item.questionBankId"
              v-for="(item, index) in questionBankList"
              :key="index"
              >{{ item.questionBankType }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="实名验证">
          <RadioGroup v-model="formValidate.userVerified">
            <Radio label="1">是</Radio>
            <Radio label="0">否</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="每局题目数">
          <InputNumber v-model="formValidate.roundQuestionNumber" placeholder="请输入每局题目数" :min="1" :max="999999999" style="width:100%;margin:0">
          </InputNumber>
        </FormItem>
        <FormItem label="每题限时(s)">
          <InputNumber v-model="formValidate.questionLimit" placeholder="请输入每题限时" :min="1" :max="999999999" style="width:100%;margin:0">
          </InputNumber>
        </FormItem>
        <FormItem label="参与人数">
          <InputNumber v-model.trim="formValidate.participantsNumber" placeholder="请输入参与人数" :min="1" :max="999999999" style="width:100%;margin:0">
          </InputNumber>
        </FormItem>
        <FormItem label="中奖人数">
          <InputNumber v-model="formValidate.winnersNumber" placeholder="请输入中奖人数" :min="1" :max="999999999" style="width:100%;margin:0">
            <span slot="append">人</span>
          </InputNumber>
        </FormItem>
        <FormItem label="评奖方式">
          <Select v-model="formValidate.awardMethod">
            <Option value="1">用时最短排行</Option>
          </Select>
        </FormItem>
        <FormItem>
           <span slot="label" class="startEnd">有效领奖时间:</span>
            <Row>
                <Col span="11">
                <FormItem prop="effectiveTime">
                   <DatePicker type="date" style="width: 100%" placeholder="请选择开始时间" v-model="formValidate.effectiveTime"></DatePicker>
                </FormItem>
                </Col>
                <Col span="1" style="text-align: center">至</Col>
                <Col span="12">
                <FormItem prop="expirationTime">
                  <DatePicker type="date" style="width: 100%" placeholder="请选择结束时间" v-model="formValidate.expirationTime"></DatePicker>
                </FormItem>
                </Col>
            </Row>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      questionBankList: []
    };
  },
  methods: {
   
  },
  created() {
    this.$get('/voteapi/api/pc/componentQuestion/selectQuestionBank',{
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if(res.code == 200) {
        this.questionBankList = res.dataList;
      }
    })
  }
};
</script>
    
<style scoped lang='less'>
.rule {
  width: 100%;
  .ivu-form {
    margin: 0 30px;
  }
  .number-vote {
    .number-vote-setting {
      display: flex;
      margin-right: 20px;
      float: left;
    }
  }
  .ivu-input-number {
    margin: 0 10px;
  }
  .page-element {
    .ivu-select {
      width: 30%;
    }
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
.startEnd {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>