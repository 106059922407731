<template>
  <div class="high-setting">
    <Form
      ref="formValidate"
      :model="formValidate"
      :label-width="160"
      :label-colon="true"
      :rules="ruleValidate"
    >
      <FormItem>
        <span slot="label" class="startEnd">每天答题起始:</span>
        <FormItem  style="float: left" prop="valiStartTime">
        <div>
          <span>每天</span>
            <TimePicker
              type="time"
              placeholder="请选择开始时间"
              v-model="formValidate.valiStartTime"
            ></TimePicker>
          <span>之前不能答题</span>
        </div>
         </FormItem>
         <FormItem style="margin-left: 30px;float: left" prop="valiEndTime">
           <div>
             <span>每天</span>
             <TimePicker
                 type="time"
                 placeholder="请选择结束时间"
                 v-model="formValidate.valiEndTime"
             ></TimePicker>
             <span>之后不能答题</span>
           </div>
         </FormItem>
      </FormItem>
      <!-- 暂时不加 -->
      <!-- <FormItem label="成功分享邀请新用户" prop="openDiscuss">
        <RadioGroup v-model="formValidate.openDiscuss">
          <Radio :label="item.dictKey" v-for="(item,index) in formValidate.openDiscussList" :key="index">{{item.dictValue}}</Radio>
        </RadioGroup>
        <div style="margin-left: 10px;display: inline-block" v-if="formValidate.openDiscuss == formValidate.openDiscussList[0].dictKey">
          <InputNumber :max="10" :min="1" v-model="formValidate.second"></InputNumber>
          <span style="margin-left: 10px">次答题机会</span>
        </div>
      </FormItem> -->
      <FormItem label="评论">
        <RadioGroup v-model="formValidate.whetherComment">
          <Radio :label="item.dictKey" v-for="(item,index) in formValidate.openDiscussList" :key="index">
            {{ item.dictValue }}
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否推荐">
        <RadioGroup v-model="formValidate.recommend">
          <Radio label="1">是</Radio>
          <Radio label="0">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="推送方式" >
          <Checkbox  v-model="formValidate.pushChannel" >小程序订阅通知</Checkbox>
      </FormItem>
      <!-- 暂时不加 -->
      <!-- <FormItem label="主题背景" class="form-item" style="width: 100%">
         <img ref="image" src="" alt=""  width="200" style="display:block;margin-bottom:10px">
          <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
        <Upload
            :before-upload="beforeImage"
            :show-upload-list="false"
            :action="actionImage"
            :data="uploadImageData"
            :on-success="(value, file) => imageSuccess(file)"
            :format="['jpg', 'jpeg', 'png']"
            :on-format-error="imageError"
            :on-progress="imageProgress"
          >
            <Button type="info">上传图片</Button>
          </Upload>
      </FormItem> -->
    </Form>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
         // 图片上传参数
      uploadImageData: {},
       //图片名称
      imageName: "",
       //图片路径
      imagePath: "",
       //oss上传地址
      actionImage: "",
      formData: {},
      file: {
        showProgress: ''
      },
    };
  },
  methods: {
    //图片上传进度
    imageProgress(event,file,fileList) {
      this.file = file;
       event.target.onprogress = (event) => {
      let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2))	// 保留两位小数，具体根据自己需求做更改
         // 手动设置显示上传进度条 以及上传百分比
      file.showProgress = true
      file.percentage = uploadPercent
       }
    },
     //图片格式错误
    imageError(file) {
       this.$Notice.warning({
          title: '上传文件格式错误！',
          desc: '文件 ' + file.name + ' 上传失败, 请上传jpg, jpeg, png格式的图片！'
      });
    },
    //图片上传成功
    imageSuccess(file) {
      if (file.status == "finished") {
        this.$refs.image.src = this.imagePath;
        this.formValidate.settingImgPath = this.imagePath;
         this.$Message["success"]({
          background: true,
          content: "图片上传成功！",
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "图片上传失败！",
        });
      }
    },
    //上传图片之前
    beforeImage(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = Date.parse(new Date()) / 1000;
          this.actionImage = "https://liefeng.oss-cn-shenzhen.aliyuncs.com";
          this.uploadImageData = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.imageName = now + file.name;
          this.imagePath = res.data.host + res.data.key + this.imageName;
        }
      });
    },
    save() {
      this.$refs.formValidate.validate(valid => {
        if(valid) {
           this.$emit("save");
        }
      })
    },
  },
};
</script>

<style scoped lang='less'>
.high-setting {
  width: 100%;
  .ivu-date-picker {
    width: 180px;
    margin: 0 6px;
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
.startEnd {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>
